import FBA from './FBA.vue'
export default {
    components: {
        FBA
    },
    data() {
        return {
            tab: 1,
            
        }
    },
    created() { 
    },
    methods: {
        toTab(val) {
            this.tab = val
        },
        toFBAPrice() {
            this.$router.push('/quotedPrice')
        },
    }
}