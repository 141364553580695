<template>
  <div class="FBA">
      <div class="video-box">
          <!-- <video autoplay="autoplay" loop="loop" muted="muted" >
              <source src="https://kjhaoyun.oss-cn-shenzhen.aliyuncs.com/staticfile/kjhy-banner.mp4" type="video/mp4">
          </video> -->
          <h1>一站式查看全网跨境物流报价</h1>
            <div class="search-box">
            <div class="tab">
                <div class="tab-item " :class="tab == 1 ? 'tab-item_active': ''" @click="toTab(1)">
                    <i class="el-icon-house"></i>
                    FBA头程
                </div>
                <div class="tab-item" :class="tab == 2 ? 'tab-item_active': ''" @click="toTab(2)">
                    <i class="el-icon-box"></i>
                    电商小包
                </div>
                <div class="tab-item" :class="tab == 3 ? 'tab-item_active': ''" @click="toTab(3)">
                    <i class="el-icon-takeaway-box"></i>
                    传统联运
                </div>
            </div>
            <div class="tab-cont">
                <div class="fba-cont" v-show="tab == 1">
                    <div class="fba-item origin">
                        <label for="">起运地</label>
                        <div class="text-box text-box-active">
                            <div class="text">
                                <input type="text">
                            </div>
                            <i class="el-icon-caret-top"></i>
                        </div>
                    </div>
                    <div class="fba-item destination">
                        <label for="">目的地</label>
                        <div class="flex">
                            <div class="location">
                                <div class="text-box text-box-active">
                                    <div class="text">
                                        <input type="text">
                                    </div>
                                    <i class="el-icon-caret-top"></i>
                                </div>
                            </div>
                            <div class="store">
                                <div class="text-box text-box-active">
                                    <div class="text">
                                        <input type="text" placeholder="请选择仓库类型（选填）">
                                    </div>
                                    <i class="el-icon-caret-top"></i>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="fba-item goods-name">
                        <label class="goods-tit">
                            货物信息
                            <span>(输入货品，体验智能报价功能)</span>
                        </label>
                        <div class="flex">
                            <div class="flex sel ivu-input-wrapper textdiv" >
                                <input type="text" placeholder="尝试输入包包、手表等货品名称" maxlength="30">
                            </div>
                            <div class="flex input-box textdiv" >
                                <input type="text" placeholder="总重量">
                                <span>KG</span>
                            </div>
                            <div class="flex input-box textdiv" >
                                <input type="text" placeholder="总体积">
                                <span>CMB</span>
                            </div>
                        </div>
                    </div>
                    <div class="fba-btn" @click="toFBAPrice()">
                        查询
                    </div>
                </div>
                <div class="fba-cont" v-show="tab == 2">
                    <div class="flex small">
                        <div class="small-item origin">
                            <label for="">
                                <i class="el-icon-location-information"></i>
                                起运地
                            </label>
                            <div style="flex: 1 1 0%">
                                <div class="text-box">
                                    <div class="text">
                                        <input type="text">
                                    </div>
                                    <i class="el-icon-caret-top"></i>
                                </div>
                            </div>
                        </div>
                        <div class="small-item destination">
                            <label for="">
                                <i class="el-icon-location-information"></i>
                                目的地
                            </label>
                            <div style="flex: 1 1 0%">
                                <div class="text-box">
                                    <div class="text">
                                        <input type="text">
                                    </div>
                                    <i class="el-icon-caret-top"></i>
                                </div>
                            </div>
                        </div>
                        <div class="small-item goods-info">
                            <div class="goods-title">
                                货物信息
                                <span>(选填，可试算)</span>
                            </div>
                            <div class="flex">
                                <div class="text-box select">
                                    <div class="text">普货</div>
                                    <i class="el-icon-caret-bottom"></i>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="input-box ivu-input-wrapper input flex">
                                    <input type="text">
                                    <span>KG</span>
                                </div>
                            </div>
                        </div>
                        <div class="small-btn">
                            查询
                        </div>
                    </div>
                </div>
                <div class="fba-cont ctly" v-show="tab == 3">
                    <div class="flex content ">
                        <div class="channelType">
                            <span>全部</span>
                        </div>
                        <div class="address-box">
                            <div class="add-item">
                                <input type="text" placeholder="请选择发货地址">
                            </div>
                            <div class="add-item">
                                <label for="">
                                    <i class="el-icon-place"></i>
                                    <span>起运地：</span>
                                </label>
                            </div>
                        </div>
                        <div class="address-box">
                            <div class="add-item">
                                <input type="text" placeholder="请选择目的国">
                            </div>
                            <div class="add-item">
                                <label for="">
                                        <i class="el-icon-location"></i>
                                        <span>目的国：</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="ctly-btn">
                        查询
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="special-price">
          <div class="price-inner">
              <div class="price-tit">
                <el-divider><h1> 特价线路</h1></el-divider>
              </div>
              <div class="price-main">
                  <div class="price-main-tit">
                      <h2>专属推荐</h2>
                  </div>
                  <div class="p-main">
                      <el-row :gutter="20">
                        <el-col :span="8" v-for="(item,index) in 6 " :key="index">
                            <div class="p-item">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix">
                                        <h2>欧洲海派(包税)</h2>
                                        <span class="tit-span">自营</span>
                                    </div>
                                    <div class="p-item-main">
                                        <div>
                                            <p class="time"> <label>提取时效：</label> 25-28工作日</p>
                                            <p class="city"><label>起运城市：</label>深圳</p>
                                        </div>
                                        <p>
                                            分泡：不分泡 / 计泡系数： 6000
                                        </p>
                                        <div>
                                            <label>单价：<i>￥<span>9.5</span>/KG</i>起</label>
                                            <button type="button">立即下单</button>
                                        </div>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                      </el-row>
                  </div>
              </div>
          </div>
      </div>
      <div class="advertising">
          <div class="advertising-inner">
            <h2>关注[哒哒通]微信公众号</h2> 
            <p>随时随地询报价，每日推荐全网最热门线路给您！</p>
            (可放宣传图片)
          </div>
      </div>
  </div>
</template>

<script>
    import FBA from './FBA.js';
    export default FBA;
</script>

<style scoped>
    @import './FBA.css';
</style>